.App {
  overflow-x: hidden;
  background-color:#292929;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.description{
  text-align: justify;
  text-justify: inter-word;
}

.projectRow {
  align-content: center;
    text-align: center;
  text-justify: inter-word;
  align-items: center;
  min-height: 300px;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  background-color: #c2d8fd;
  -webkit-box-shadow: 9px 7px 48px -19px black;
  -moz-box-shadow: 9px 7px 48px -19px black;
  box-shadow: 9px 7px 48px -19px black;
  box-shadow: 0px 0px 151px -59px rgba(0,0,0,0.75) inset;
  -webkit-box-shadow: 0px 0px 151px -59px rgba(0,0,0,0.75) inset;
  -moz-box-shadow: 0px 0px 151px -59px rgba(0,0,0,0.75) inset;
}

.header_container{
  border-radius: 15px;
  background-color: #c2d8fd;
  margin: 10px;
  padding: 10px;
  -webkit-box-shadow: inset 9px 9px 34px -15px black;
  -moz-box-shadow: inset 9px 9px 34px -15px black;
  box-shadow: inset 9px 9px 34px -15px black;
  -webkit-box-shadow: 9px 9px 34px -15px black;
  -moz-box-shadow: 9px 9px 34px -15px black;
  box-shadow: 9px 9px 34px -15px black;
}

.tecnologiasContainer{
  margin-left: 0px;
  margin-bottom: 8px;
}

.project{
  align-content: center;
  align-items: center;
}

.projectCol {
  width: 50%;
  align-content: center;
  align-items: center;
  margin: 10px;
  padding: 15px;
  border-width: 1px;
  border-color: #282c34;
}

.descripcion_container{
  margin:5px;
  margin-left: 3px;
  padding: 5px;
  border-radius: 8px;
  -webkit-box-shadow: inset 10px 10px 34px -15px grey;
  -moz-box-shadow: inset 10px 10px 34px -15px grey;
  box-shadow: inset 10px 10px 34px -15px grey;
  -webkit-box-shadow: 10px 10px 34px -15px grey;
  -moz-box-shadow: 10px 10px 34px -15px grey;
  box-shadow: 10px 10px 34px -15px grey;
}

.imagen_container{
  border:10px white;
  border-radius: 8px;
  -webkit-box-shadow: inset 10px 10px 34px -15px black;
  -moz-box-shadow: inset 10px 10px 34px -15px black;
  box-shadow: inset 10px 10px 34px -15px black;
  -webkit-box-shadow: 10px 10px 34px -15px black;
  -moz-box-shadow: 10px 10px 34px -15px black;
  box-shadow: 10px 10px 34px -15px black;
}

.tecnologia {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  margin: 1px;
  font-size: 14px;
  height: 22px;
  padding-bottom:0;
  border-radius: 5px;
}

.App-header {
  min-height: 350px;
  background-color: white;
  display: flex;
  font-size: calc(10px + 2vmin);
}

.footer {
  background-color:#292929;
  color:white;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  height: 100px;
}

.nav-bar{
  background-color: #292929;
}

.titulo{
  color:white;
  font-weight: normal;
  margin: auto;
  border: black;
  border-width: 1px;
  font-size: 20px;
}

.brand{
  display: flex;
  flex-direction: row;
  width: 195px;
  justify-content: space-between;
}

.perfil{
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 15px;
  margin: 2px;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}